.App {
  text-align: center;
}
.Container {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
  background: #aaa;
  margin-top: 40px;
}
.Stage {
  padding: 20px;
  display: inline-block;
}
.Log {
  color: #333;
}